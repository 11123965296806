import React, {useContext, useEffect, useState} from 'react';
import { motion } from 'framer-motion';

import { ProductContext } from '../contexts/ProductContext';
import Product from '../components/Product';
import Header from '../components/Header';
import { fadeIn, footerVariants, staggerContainer } from '../utils/motion';
import { TitleText, TypingText } from '../components/CustomTexts';
import { Link } from 'react-router-dom';

import WomanImg from '../img/bg2.webp'
import Footer from '../components/Footer';

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    const interval = setInterval(() => {
      // Your code here
      setLoading(false);
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  const { products } = useContext(ProductContext);
  const filteredProducts = products.filter((item) => {
    return (
      item.category === "men's clothing" || item.category === "women's clothing"
    )
  })
  console.log(filteredProducts)
  return <div>
    <Header/>
    {/* Hero Section */}
    <section className=" bg-[#ECE6D0] h-[800px] bg-hero1 bg-no-repeat bg-cover bg-center py-24">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className=" container mx-auto flex justify-around h-full"
        >
          <motion.div 
          variants={fadeIn("right", "tween", 0.2, 1)}
          className=" flex flex-col justify-center ">
            <div className=' font-semibold flex items-center uppercase'>
              <div className=' w-10 h-[2px] bg-primary mr-3 '></div>
              <div className=' text-primary'>New Trend</div>
            </div>

            <h1 className=' uppercase text-[70px] leading-[1.1] font-light mb-4 text-primary'>
              Men, Women Stylish <br /> <span className=' font-semibold uppercase text-[100px]'>Wears</span>
            </h1>
            <a href='#catalogue' className='self-start uppercase font-semibold border-b-2 border-white  text-primary'>Discover More</a>
          </motion.div>

          <motion.div 
          variants={fadeIn("left", "tween", 0.2, 1)}
          className=' hidden sm:flex'>
            <img src={WomanImg} alt={`Woman Image`} className=' rounded-3xl w-[30rem]'  />
        </motion.div>
        </motion.div>
      </section>

    {/* Catalogue */}
    <section id='catalogue' className='py-16 bg-[#FFFAF4] -z-30'>
      <motion.div 
      variants={fadeIn("right", "tween", 0.2, 1)}
      className=' max-w-6xl mx-auto px-4 sm:px-6'>
        <div className='max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-4 items-start md:max-w-2xl lg:max-w-none sm:mt-20' >
          {filteredProducts.map((product) => {
            return <Product product={product} id={product.id} key={product.id} /> 
          })}
        </div>
      </motion.div>
    </section>

    {/* Footer */}
    <Footer />
  </div>;
};

export default Home;
