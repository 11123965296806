import React, {useState, useEffect,  createContext} from 'react';

// Create Context 
export const CartContext = createContext();

const CartProvider = ({children}) => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);

  const [itemAmount, setItemAmount] = useState(0);



  useEffect(() => {
    if (cart) {
      const amount = cart.reduce((accumulator, currentItem)=> {
        return accumulator + currentItem.amount;
      }, 0)
      setItemAmount(amount);
    }
  }, [cart]);

  useEffect(() => {
    const total = cart.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.amount  * currentItem.price;
    }, 0);
    setTotal(total);
  });

  // console.log(total)

  const addToCart = (product, id) => {
    const newItem = {...product, amount:1};
    // console.log(`Item ${product.title} has been added to cart `)

    // We need to check if the product is already in the cart
    const cartItem =  cart.find(item => item.id === id);

    // If cart item is already in the cart
    if (cartItem) {
      const newCart = [...cart].map(item => {
        if (item.id === id) {
          return {...item, amount: cartItem.amount + 1}
        } else {
          return item;
        }
      });
      setCart(newCart); 
    }else{
      setCart([...cart, newItem]);
    }
  };

  const removeFromCart = (id) => {
    const newCart = cart.filter((item) => {
      return item.id !== id;
    })
    setCart(newCart);
  }

  const clearCart = () => {
    setCart([]);
  }

  const increaseAmount = (id) => {
    const cartItem = cart.find((item) => item.id === id);
    addToCart(cartItem, id);
  }

  const decreaseAmount = (id) => {
    const cartItem = cart.find((item) => {
      return item.id === id;
    });
    if (cartItem) {
       const newCart = cart.map((item) => {
        if (item.id === id) {
          return {...item, amount: cartItem.amount - 1 }
        } else {
          return item;
        }
       });
       setCart(newCart);
    }
    
    if (cartItem.amount < 2) {
      removeFromCart(id);
    }
  }

  // console.log(cart)


  return <CartContext.Provider value={{cart, addToCart, removeFromCart, clearCart, decreaseAmount, increaseAmount, itemAmount, setItemAmount, total, setTotal}}>{children}</CartContext.Provider>;
};

export default CartProvider; 
