import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import { SidebarContext } from "../contexts/SidebarContext";
import Footer from "../components/Footer";
import {
  BsArrowBarRight,
  BsBank,
  BsCart4,
  BsConeStriped,
} from "react-icons/bs";
import { CartContext } from "../contexts/CartContext";
import {loadStripe} from '@stripe/stripe-js';

const CheckoutPage = () => {
  const { isOpen, setIsOpen, handleClose } = useContext(SidebarContext);
  const { cart, itemAmount, total, clearCart } = useContext(CartContext);

  // console.log(cart)
  // console.log(itemAmount)



  // const discount = 100;
  const deliveryFee = 50
  // const payable = total - discount + deliveryFee
  const payable = total + deliveryFee
  const payableSum = payable.toLocaleString()

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    const interval = setInterval(() => {
      // Your code here
      setLoading(false);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  console.log(payableSum);

  useEffect(() => {
    handleClose(!isOpen);
  }, []);



  const makePayment = async()=>{
    const stripe = await loadStripe(`pk_test_51LeZuDExjE4eTZVBgqGaeAdOZC6HPkSamfIPSVuQNjX5VBHlipDK9PktnSjG24CpogmKUvYkn2YgwuyKvJLjCSvN00MqO2vpzo`);

    const body = {
        products:cart,
        deliveryCost: deliveryFee
    }
    const headers = {
        "Content-Type":"application/json"
    }
    const response = await fetch("https://app.blssmstudios.com/api/create-checkout-session",{
    // const response = await fetch("http://localhost:5252/api/create-checkout-session",{
        method:"POST",
        headers:headers,
        body:JSON.stringify(body)
    });

    const session = await response.json();

    const result = stripe.redirectToCheckout({
        sessionId:session.id
    });
    
    if(result.error){
        console.log(result.error);
    }
}


  return (
    <div>
      <Header />

      <section className=" bg-[#FFFAF4]  my-28  flex flex-col items-center">
        <div className=" text-3xl font-bold text-primary uppercase">Checkout</div>
        <div className="flex sm:flex-row flex-col-reverse w-full gap-4 p-4 container mx-auto">
          <div className=" flex flex-1 flex-col">
            <div className=" border border-gray-500 border-dashed p-3 bg-gray-100">
              <p className=" text-gray-500 font-semibold">
                Complete your Order
              </p>
            </div>

            <div className=" border-b border-gray-300 my-8" />

            <div className=" border border-gray-500 border-dashed bg-gray-200 p-3 w-full">
              <p className=" font-semibold">Order Summary</p>
              {itemAmount === 0 ? <div></div> :<div className=" flex flex-col gap-3">
                <div
                  onClick={() => setIsOpen(!isOpen)}
                  className=" cursor-pointer flex flex-row items-center justify-between my-2"
                >
                  <p className=" text-gray-700 text-sm mb-2 underline">
                    Manage Items in cart
                  </p>
                  <BsArrowBarRight />
                </div>

                <div className=" text-4xl font-normal text-primary text-center">
                  {itemAmount} items
                </div>

                <div className=" border-b border-gray-300 my-1" />
                <div className="  flex flex-row items-center  justify-between my-2">
                  <p className=" text-gray-700 text-sm mb-2 ">Sub Total</p>
                  <p className=" font-semibold">${total.toLocaleString()}</p>
                </div>
                {/* <div className=" border-b border-gray-300 my-1" />
                <div className="  flex flex-row items-center  justify-between my-2">
                  <p className=" text-gray-700 text-sm mb-2 ">Discount</p>
                  <p className=" font-semibold">-${discount}</p>
                </div> */}
                <div className=" border-b border-gray-300 my-1" />
                <div className="  flex flex-row items-center  justify-between my-2">
                  <p className=" text-gray-700 text-sm mb-2 ">Delivery Service</p>
                  <p className=" font-semibold">${deliveryFee}</p>
                </div>
                <div className=" border-b border-gray-300 my-1" />
                <div className="  flex flex-row items-center  justify-between my-2">
                  <p className=" text-gray-700 text-sm mb-2 font-semibold">Total </p>
                  <p className=" text-xl font-semibold">${ parseFloat(payableSum) }</p>
                </div>
              </div>}
            </div>
          </div>
          <div className="  flex flex-[0.2] flex-col w-full h-80">
            
            <div className=" flex flex-col gap-1">
              <button onClick={makePayment} className=" w-full flex flex-row cursor-pointer bg-[#31305C] hover:bg-[#000] hover:text-[#ECE6D0] gap-2 text-white justify-center font-semibold items-center text-sm p-4 capitalize transition ease-in duration-300 mt-2">
                <BsConeStriped />
                <div>Pay with Stripe</div>
              </button>

              {/* <button  className=" w-full flex flex-row cursor-pointer bg-[#ECE6D0] hover:bg-black hover:text-[#ECE6D0] gap-2 text-primary justify-center font-semibold items-center text-sm p-2 capitalize transition ease-in duration-300 mt-2">
                <BsBank />
                <div>Pay with Bank Transfer</div>
              </button> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CheckoutPage;
