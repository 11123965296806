import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { CartContext } from "../contexts/CartContext";
import { ProductContext } from "../contexts/ProductContext";
import { Link, useParams } from "react-router-dom";
import WomanImg from "../img/404.svg";
import { fadeIn, staggerContainer } from "../utils/motion";
import { motion } from "framer-motion";
import { BsArrowLeft, BsCart4 } from "react-icons/bs";
import Header from "../components/Header";
import useAlert from "../hooks/useAlert";
import Alert from "../components/Alert";
import ProjectDetailsHeader from "../components/ProjectDetailsHeader";
import Footer from "../components/Footer";
import { SidebarContext } from "../contexts/SidebarContext";
// Link
// motion
// ProductContext

const ProductDetails = () => {
  const { id } = useParams();
  const { products } = useContext(ProductContext);
  const { addToCart1 } = useParams();
  const { addToCart } = useContext(CartContext);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);

  const { alert, showAlert, hideAlert } = useAlert();


  const { isOpen, handleClose } = useContext(SidebarContext);

  useEffect(() => {
      handleClose(!isOpen)
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const interval = setInterval(() => {
      // Your code here
      setLoading(false);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  }, []);

  const product = products.find((product) => {
    return product.id === parseInt(id);
  });

  if (!product) {
    return (
      <section className=" bg-[#ffffff] h-[800px] bg-hero1 bg-no-repeat bg-cover bg-center py-24">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className=" container mx-auto flex justify-around h-full"
        >
          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className=" flex flex-col justify-center  "
          >
            <h1 className=" text-center  uppercase text-[15px] leading-[1.1] font-light mb-4 text-primary">
              BLSSM STUDIO.
            </h1>
            <h1 className=" items-center capitalize text-[30px] leading-[1.1] font-light mb-4 text-primary">
              The page you are looking for was not
            </h1>
            <span className=" font-semibold uppercase text-[100px] text-center">
              found!{" "}
            </span>
            <Link
              to={`/products`}
              className=" self-center uppercase font-semibold border-b-2 border-primary  text-primary"
            >
              Back to products
            </Link>
          </motion.div>

          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className=" hidden sm:flex"
          >
            <img
              src={WomanImg}
              alt={`Woman Image`}
              className=" rounded-3xl w-[25rem]"
            />
          </motion.div>
        </motion.div>
      </section>
    );
  }

  const { image, title, price, description } = product;

  const handleClick = () => {
    try {
      addToCart(product, product.id);
      // TODO: Shows success message
      showAlert({
        show: true,
        text: "Product successfully added to your cart",
        type: "success",
      });

      // TODO: Show error message
      setTimeout(() => {
        hideAlert(false);
      }, [1000]);
    } catch (error) {
      showAlert({
        show: true,
        text: "An error occurred",
        type: "danger",
      });

      setTimeout(() => {
        hideAlert(false);
      }, [1000]);
    }
  };

  return (
    <div>
      {alert.show && <Alert {...alert} />}
      <ProjectDetailsHeader />
      <div
        className={` ${
          isActive ? "top-20" : "top-10"
        } fixed  z-40 left-0 right-0 flex justify-center items-center`}
      >
        <div
          className={` p-2   bg-gray-200 mt-4 items-center justify-center text-center text-primary leading-none w-full flex lg:inline-flex `}
        >
          <p className="mr-2 text-center items-center font-medium">
            Offer Alert: We offer 10% discount on purchases above $1,000
          </p>
        </div>
      </div>
      <motion.section
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className=" relative pt-32 pb-12 lg:py-32  flex items-center sm:mt-48 mt-16 sm:mb-28 mb-16"
      >
        <Link
          to={`/products`}
          className=" absolute sm:left-16 left-8 sm:top-8 top-16 bg-[#ECE6D0] text-primary hover:bg-primary hover:text-[#FFFAF4] transition ease-in duration-300 sm:text-4xl text-xl sm:p-8 p-4 rounded-full cursor-pointer"
        >
          <BsArrowLeft />
        </Link>

        <div className=" container mx-auto">
          <div className=" flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide lg:flex-row items-center">
            <motion.div
              variants={fadeIn("left", "tween", 0.2, 1)}
              className=" flex flex-1 justify-center items-center mb-8 lg:mb-0"
            >
              <img
                src={image}
                alt={title}
                className="  cursor-crosshair max-w-[200px] lg:max-w-sm transform duration-500 ease-in-out hover:scale-105"
              />
            </motion.div>
            <motion.div
              variants={fadeIn("right", "tween", 0.2, 1)}
              className=" flex-1 text-center sm:text-left"
            >
              <div className=" flex flex-col sm:items-start items-center ">
                <h1 className=" text-[26px] font-medium mb-2 max-w-[550px] mx-auto text-start">
                  {title}
                </h1>
                <div className=" flex flex-row items-center justify-items-center sm:gap-4 gap-1">
                <div className=" text-sm text-gray-500 mb-6 uppercase">
                  Brand:
                  </div>
                <div className=" text-sm text-gray-900 mb-6 underline uppercase">
                  BLSSM premium cotton
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-row flex-col w-full items-center justify-between">
                <div className=" text-3xl text-primary font-semibold mb-6">
                  ${price}
                </div>

                <div
                  onClick={() => handleClick()}
                  className=" flex flex-row cursor-pointer bg-[#8AB415] hover:bg-black hover:text-[#ECE6D0] gap-2 text-white justify-center items-center text-sm p-3 uppercase transition ease-in duration-300 mt-2"
                >
                  <BsCart4 />
                  <div>Add to cart</div>
                </div>
              </div>

              <div className="flex flex-col w-full mt-8">
                <div className=" text-sm text-gray-500 mb-6">
                  About the product
                </div>
                <div className=" sm:text-xl text-sm  mb-8 text-justify">
                  ${description}
                </div>
              </div>
              {/* <p className="">{description}</p> */}
            </motion.div>
          </div>
        </div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default ProductDetails;
