import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home'
import ProductDetails from './pages/ProductDetails'

import Sidebar from './components/Sidebar'
import Product from './components/Product'
import Hero from './components/Hero'
import Header from './components/Header'
import Footer from './components/Footer'
import CartItem from './components/CartItem'
import LandingPage from './pages/LandingPage';
import CheckoutPage from './pages/CheckoutPage';
import Success from './pages/Success';
import Cancel from './pages/Cancel';

const App = () => {
  return <div className='overflow-hidden bg-[#FFFAF4]'>
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<LandingPage /> } />
        <Route path="/success" element={<Success /> } />
        <Route path="/error" element={<Cancel /> } />
        <Route path="/products" element={<Home /> } />
        <Route path="/checkout" element={<CheckoutPage /> } />
        <Route path="/product/:id" element={<ProductDetails /> } />
      </Routes>
      <Sidebar />
      {/* <Footer /> */}
    </Router>
  </div>;
};

export default App;
