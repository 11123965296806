import React from 'react';
import { fadeIn, staggerContainer } from '../utils/motion';
import { motion } from 'framer-motion';
// motion

const Footer = () => {
  return(
    <section className="sm:p-16 xs:p-8 px-6 py-12  bg-[#ECE6D0] relative z-10">
        <div className=" ">
          <h4
            className={`mt-[50px] font-semibold text-black lg:text-[65px] text-[26px] sm:leading-[40px] leading-[36px]`}
          >
            Capture the moment.
          </h4>
        </div>
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className={`2xl:max-w-[1280px] w-full mx-auto flex sm:flex-row flex-col`}
        >
          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="flex-[0.3] flex justify-center items-center relative"
          >
            <div></div>
          </motion.div>

          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="flex-[1.1]  lg:max-w-[870px] flex-justify-end flex-col gradient-05 sm:p-8 p-4 rounded-[0px]  relative"
          >
            <div className="feedback-gradient absolute top-10 left-20" />
            <div className=" flex sm:flex-row flex-col sm:gap-32 gap-2">
              <div className=" flex flex-col mt-16">
                <p
                  className={` underline uppercase mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black mb-5`}
                >
                  Studio
                </p>
                <p
                  className={`mt-[0px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  123 Anywhere, St., Any City, ST. 12345
                </p>
              </div>
              <div className=" flex flex-col mt-16">
                <p
                  className={` underline uppercase mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black mb-5`}
                >
                  Social
                </p>
                <p
                  className={`mt-[0px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  @blssmstudio.
                </p>
              </div>
              <div className=" flex flex-col mt-16">
                <p
                  className={` underline uppercase mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black mb-5`}
                >
                  Email
                </p>
                <p
                  className={`mt-[0px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  enquires@blssmstudio.com
                </p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </section>
  )
};

export default Footer;

// return <div className=' bg-[#000000] py-12'>
//   <div className=' container mx-auto text-center'>
//     <p className=' text-[#ECE6D0]'>Copyright &copy; BLSSM Studio 2024. All Rights Reserved.</p>
//   </div>
  
// </div>;