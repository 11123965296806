import React, { useEffect, useState } from 'react';
import { SidebarContext } from '../contexts/SidebarContext';
import { useContext } from 'react';
import { BsBag } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { CartContext } from '../contexts/CartContext'

const Header = () => {
  const [isActive, setIsActive] =  useState(false);
  const {isOpen, setIsOpen} = useContext(SidebarContext)
  const {itemAmount, setItemAmount} = useContext(CartContext);


  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    })
  }, []);


  return (
    <header className={`${isActive ? "bg-[#FFFAF4] " : "bg-[#5d4b3e] "} w-full flex py-4 px-4 justify-between items-center shadow-sm fixed top-0 z-10 `}>
      <Link to={`/`}><div className={`${isActive ? "text-[#252525] " : "text-[#ECE6D0] "} text-2xl font-semibold`}>BLSSM STUDIOS.</div></Link>
      <div onClick={()=> setIsOpen(!isOpen)} className=' text-gray-900 cursor-pointer flex relative'>
        <BsBag className={`${isActive ? "text-[#252525] " : "text-[#ECE6D0] "} text-2xl`}/>
        <div className={` ${isActive ? "bg-[#252525] text-[#FFFAF4]" : "bg-[#ECE6D0] text-[#252525]"}  absolute -right-2 -bottom-2 rounded-full  text-[12px] w-[18px] h-[18px] justify-center items-center flex transition ease-in duration-300`}>{itemAmount}</div>
      </div>
    </header>
  );
};

export default Header;
