import React, { useEffect, useState } from "react";
import { SidebarContext } from "../contexts/SidebarContext";
import { useContext } from "react";
import { BsBag, BsCart4 } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
// useParams

import { CartContext } from "../contexts/CartContext";
import { ProductContext } from "../contexts/ProductContext";

import useAlert from "../hooks/useAlert";
import Alert from "../components/Alert";
import { IoMdAdd, IoMdRemove } from "react-icons/io";

const ProjectDetailsHeader = () => {
  const { id } = useParams();
  const { products } = useContext(ProductContext);
  const [isActive, setIsActive] = useState(false);
  const { isOpen, setIsOpen } = useContext(SidebarContext);
  const { itemAmount, setItemAmount } = useContext(CartContext);

  const { alert, showAlert, hideAlert } = useAlert();
  const { addToCart, decreaseAmount, increaseAmount } = useContext(CartContext);

  const product = products.find((product) => {
    return product.id === parseInt(id);
  });

  const handleClick = () => {
    try {
      addToCart(product, product.id);
      // TODO: Shows success message
      showAlert({
        show: true,
        text: "Product successfully added to your cart",
        type: "success",
      });

      // TODO: Show error message
      setTimeout(() => {
        hideAlert(false);
      }, [2000]);
    } catch (error) {
      showAlert({
        show: true,
        text: "An error occurred",
        type: "danger",
      });

      setTimeout(() => {
        hideAlert(false);
      }, [2000]);
    }
  };

  const { image, title, price, description, } = product;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  }, []);

  return (
    <div>
      {alert.show && <Alert {...alert} />}
      {isActive ? (
        // header new
        <header
          className={` bg-[#ECE6D0] w-full flex py-6 px-4 justify-between items-center shadow-sm fixed top-0 z-10 `}
        >
          <Link to={`/`}>
            <div
              className={` text-[#252525] sm:max-w-[450px] max-w-[200px] sm:text-xl text-sm font-semibold`}
            >
              {title}
            </div>
          </Link>
          <div className=" flex flex-row gap-4">
            
            <div className=" hidden sm:flex flex-col w-28">
              <div className=" text-sm text-gray-500">Price</div>
              <div className=" sm:text-2xl text-xl font-semibold">${price}</div>
            </div>
            <div  onClick={() => handleClick()} className=" flex sm:w-40 w-24 bg-[#8AB415] hover:bg-primary p-3 transition ease-in duration-300 cursor-pointer text-[#ECE6D0] text-center items-center justify-center">Buy Now</div>
          </div>
          {/* <div
            onClick={() => handleClick()}
            className=" text-gray-900 cursor-pointer flex relative"
          >
            <BsCart4 className={` text-[#252525] text-2xl`} />
            <div
              className={`  bg-[#252525] text-[#FFFAF4]  absolute -right-2 -bottom-2 rounded-full  text-[12px] w-[18px] h-[18px] justify-center items-center flex`}
            >
              +
            </div>
          </div> */}
        </header>
      ) : (
        // header default
        <header
          className={` bg-[#5d4b3e] w-full flex py-4 px-4 justify-between items-center shadow-sm fixed top-0 z-10 `}
        >
          <Link to={`/`}>
            <div className={` text-[#ECE6D0]  text-2xl font-semibold`}>
              BLSSM STUDIOS.
            </div>
          </Link>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className=" text-gray-900 cursor-pointer flex relative"
          >
            <BsBag className={` text-[#ECE6D0] text-2xl`} />
            <div
              className={`  bg-[#ECE6D0] text-[#252525]  absolute -right-2 -bottom-2 rounded-full  text-[12px] w-[18px] h-[18px] justify-center items-center flex`}
            >
              {itemAmount}
            </div>
          </div>
        </header>
      )}
    </div>
  );
};

export default ProjectDetailsHeader;
