import React, { useContext } from "react";
import { BsEye, BsEyeFill, BsPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { motion } from "framer-motion";

import toast, { Toaster } from "react-hot-toast";

import useAlert from "../hooks/useAlert";
import HomeAlert from "./HomeAlert";
import { SidebarContext } from "../contexts/SidebarContext";
import { fadeIn } from "../utils/motion";

// CartContext

const Product = ({ product }) => {
  const {isOpen, setIsOpen} = useContext(SidebarContext)
  const { alert, showAlert, hideAlert } = useAlert();

  const { addToCart } = useContext(CartContext);

  const { id, image, category, title, price } = product;

  const handleClick = () => {
    
    
    try {
      addToCart(product, id);
      // TODO: Shows success message
      showAlert({
        show: true,
        text: "Product successfully added to your cart",
        type: "success",
      });

      // TODO: Show error message
      setTimeout(() => {
        hideAlert(false);
      }, [1000]);
    } catch (error) {
      showAlert({
        show: true,
        text: "An error occurred",
        type: "danger",
      });

      setTimeout(() => {
        hideAlert(false);
      }, [1000]);
    }
  };


  return (
    <motion.div 
    variants={fadeIn("up", "tween", 0.2, 1)}
    className=" bg-[#FFFAF4]" key={id}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options

          success: {
            style: {
              background: "#000000", // Customize success toast background
              color: "#fff",
            },
          },
          error: {
            style: {
              background: "#FFEBE6", // Customize error toast background
              color: "#E54D2B",
            },
          },

          className: " ",
          duration: 3000,
          style: {
            padding: "15px",
            border: "1px solid #ECE6D0",
          },
        }}
      />

{alert.show && <HomeAlert {...alert} />}

      <div className=" border border-[#e4e4e4] h-[300px] mb-4 relative  overflow-hidden group transition">
        <div className=" w-full h-full flex justify-center items-center">
          {/* Image */}
          <div className=" w-[200px] mx-auto flex justify-center items-center">
            <img
              src={image}
              alt={title}
              className=" max-h-[160px] group-hover:scale-110 cursor-pointer transition duration-300"
            />
          </div>
        </div>

        {/* Button */}
        <div className=" absolute top-6 -right-11 group-hover:right-2 bg-[#ECE6D0]/40 p-2 flex flex-col items-center justify-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300">
          <button onClick={() => handleClick()}>
            <div className=" flex justify-center items-center text-white w-12 h-12 bg-[#ECE6D0]">
              <BsPlus className=" text-gray-900 text-3xl" />
            </div>
          </button>
          <Link
            to={`/product/${id}`}
            className=" w-12 h-12 bg-white flex justify-center items-center text-primary drop-shadow-xl"
          >
            {" "}
            <BsEyeFill className=" text-gray-900 text-3xl" />
          </Link>
        </div>
      </div>
      <div>
        <div className=" text-sm text-gray-500 capitalize">{category}</div>
        <Link to={`/product/${id}`}>
          {" "}
          <h2 className=" text-gray-900 font-semibold mb-2  uppercase">
            {title}
          </h2>
        </Link>
        <div className=" text-2xl">${price}</div>
      </div>
    </motion.div>
  );
};

export default Product;
