import React, { useContext, useEffect, useState } from "react";
import WomanImg from "../img/woman_hero.png";
import Work1 from "../img/work1.png";
import Work from "../img/work.png";
import Where from "../img/where.png";
import Where1 from "../img/where1.png";

import Work2 from "../img/work2.png";
import Work3 from "../img/work3.png";
import Work4 from "../img/work4.png";
import Work5 from "../img/work5.png";
import Work6 from "../img/work6.png";
import Work7 from "../img/work7.png";

import { Link } from "react-router-dom";
import Header from "../components/Header";
import { TitleText, TypingText } from "../components/CustomTexts";
import { motion } from "framer-motion";
import { fadeIn, footerVariants, staggerContainer } from "../utils/motion";
import { SidebarContext } from "../contexts/SidebarContext";

const LandingPage = () => {

  const { isOpen, handleClose } = useContext(SidebarContext);

  useEffect(() => {
      handleClose(!isOpen)
  }, []);


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    const interval = setInterval(() => {
      // Your code here
      setLoading(false);
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      {/* <Header /> */}
      <section className=" bg-[#FFFAF4] h-[800px] bg-hero bg-no-repeat bg-cover bg-center py-24">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className=" container mx-auto flex justify-center h-full"
        >
          <div className=" flex flex-col justify-center items-center">
            <TypingText title={`BLSSM`} textStyles={`text-center`} />
            <TypingText title={`STUDIOS.`} textStyles={`text-center -mt-12`} />
            <TitleText
              title={`summer collection is here. `}
              textStyles={`text-center `}
            />
            {/* <h1 className='text-[120px] font-bold text-[#FFFAF4]'>BLSSM.</h1>
            <div className=''>
                <div className=' text-xl text-[#FFFAF4] lowercase'>summer collection is here.</div>
            </div> */}
            <Link to={`/products`}>
              <motion.div
                variants={footerVariants}
                initial="hidden"
                whileInView="show"
                className="mt-12 bg-[#FFFAF4] hover:bg-[#ECE6D0]  hover:text-black transition-all ease-in duration-300 w-52 text-center justify-items-center p-4 rounded-full uppercase font-semibold"
              >
                Order
              </motion.div>
            </Link>
          </div>

          {/* <div>
            <img src={WomanImg} alt={`Woman Image`} />
        </div> */}
        </motion.div>
      </section>

      <section className="sm:p-16 xs:p-8 px-6 py-12  bg-[#FFFAF4] relative z-10">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className={`2xl:max-w-[1280px] w-full mx-auto flex sm:flex-row-reverse flex-col-reverse `}
        >
          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="flex-[0.4] flex justify-center items-center relative"
          >
            <img
              src={Work}
              alt="image"
              className={`w-96 min-h-[210px] lg:h-[300px] object-cover rounded-[0px] absolute top-0`}
            />
          </motion.div>

          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="flex-1  lg:max-w-[770px] flex-justify-end flex-col gradient-05 sm:p-8 p-4 rounded-[0px]  relative"
          >
            <div className="feedback-gradient absolute top-10 left-20" />
            <div>
              <h4
                className={`mt-[50px] font-semibold text-black lg:text-[65px] text-[26px] sm:leading-[40px] leading-[36px]`}
              >
                What I Shoot
              </h4>

              <div className=" flex mt-32">
                <div className="flex sm:flex-row flex-col-reverse gap-20">
                  <img
                    src={Work1}
                    alt="image"
                    className={` w-72 min-h-[150px] lg:h-[250px] object-cover rounded-[0px] `}
                  />
                  <div className=" flex flex-col gap-6">
                    <div>
                      <p
                        className={`mt-[8px] mb-5 font-normal sm:text-[22px] text-[18px] sm:leading-[22.68px] leading-[16.68px] text-black underline`}
                      >
                        Creative Work
                      </p>
                      <p
                        className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                      >
                        Personal passion projects
                      </p>
                    </div>
                    {/*  */}
                    <div>
                      <p
                        className={`mt-[8px] mb-5 font-normal sm:text-[22px] text-[18px] sm:leading-[22.68px] leading-[16.68px] text-black underline`}
                      >
                        Editorial
                      </p>
                      <p
                        className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                      >
                        Published online and in-print
                      </p>
                    </div>
                    {/*  */}
                    <div>
                      <p
                        className={`mt-[8px] mb-5 font-normal sm:text-[22px] text-[18px] sm:leading-[22.68px] leading-[16.68px] text-black underline`}
                      >
                        Stylized Shoots
                      </p>
                      <p
                        className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                      >
                        Specially commissioned projects
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <p
                className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
              >
                Founder, Metaversus
              </p>

              <div className={`flex flex-[0.5] flex-wrap `}>
                <p className="lg:text-[24px] text-[18px] text-black mt-[16px] sm:leading-[45.6px] leading-[39.6px]">
                  “With the development of today's technology, metaverse is very
                  useful for today's work, or can be called web 3.0. by using
                  metaverse you can use it as anything”
                </p>
              </div> */}
            </div>
          </motion.div>
        </motion.div>
      </section>

      <section className="sm:p-16 xs:p-8 px-6 py-12  bg-[#ECE6D0] relative z-10">
        <div className=" ">
          <h4
            className={`mt-[50px] font-semibold text-black lg:text-[65px] text-[26px] sm:leading-[40px] leading-[36px]`}
          >
            Why I Shoot
          </h4>
        </div>
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className={`2xl:max-w-[1280px] w-full mx-auto flex sm:flex-row flex-col`}
        >
          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="flex-[0.3] flex justify-center items-center relative"
          >
            <div></div>
          </motion.div>

          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="flex-[1.1]  lg:max-w-[670px] flex-justify-end flex-col gradient-05 sm:p-8 p-4 rounded-[0px]  relative"
          >
            <div className="feedback-gradient absolute top-10 left-20" />
            <div>
              <div className=" flex flex-col mt-16">
                <p
                  className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black mb-5`}
                >
                  Anyone can take a picture, but not everyone can{" "}
                  <i className=" font-semibold">
                    capture the right mood or moment
                  </i>
                  . I am most at home behind a camera, and I feel connected to
                  the world through my lens.
                </p>
                <p
                  className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  I fell in love with photography when I realized I could tell
                  stories through images. I shoot because of an insatiable need
                  to share these narratives with the world - and editorial
                  photography allows me to do that, every single day.
                </p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </section>

      <section className="sm:p-16 xs:p-8 px-6 py-12  bg-[#FFFAF4] relative z-10">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className={`2xl:max-w-[1280px] w-full mx-auto flex sm:flex-row-reverse flex-col-reverse `}
        >
          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="flex-[0.4] flex justify-center items-center relative"
          >
            <div className=" flex flex-col sm:mt-52 mt-6">
              <div className=" mb-5">
                <p
                  className={`mt-[8px] mb-5 font-normal sm:text-[22px] text-[18px] sm:leading-[22.68px] leading-[16.68px] text-black underline`}
                >
                  Digital Publications
                </p>
                <p
                  className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  Websites, blogs and other online platforms
                </p>
              </div>
              <img
                src={Where1}
                alt="image"
                className={`w-80 min-h-[210px] lg:h-[400px] object-cover rounded-[0px]`}
              />
            </div>
          </motion.div>

          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="flex-[1]  lg:max-w-[770px] flex-justify-end flex-col gradient-05 sm:p-8 p-4 rounded-[0px]  relative"
          >
            <div className="feedback-gradient absolute top-10 left-20" />

            <div className="flex flex-col">
              <h4
                className={`mt-[50px] font-semibold text-black lg:text-[65px] text-[26px] sm:leading-[40px] leading-[36px]`}
              >
                Where My Work Goes
              </h4>

              <div className=" mt-16">
                <img
                  src={Where}
                  alt="image"
                  className={`w-full min-h-[210px] lg:h-[300px] object-cover rounded-[0px] `}
                />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </section>

      <section className="sm:p-16 xs:p-8 px-6 py-12  bg-[#FFFAF4] relative z-10">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className={`2xl:max-w-[1280px] w-full mx-auto flex sm:flex-row-reverse flex-col-reverse `}
        >
          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="flex-[1] flex justify-center items-center relative"
          >
            <div className=" flex flex-col sm:mt-52 mt-6 w-[80%]">
              <div className=" mb-5">
                <p
                  className={`mt-[8px] mb-5 font-normal sm:text-[22px] text-[18px] sm:leading-[22.68px] leading-[16.68px] text-black underline`}
                >
                  Digital Publications
                </p>
                <p
                  className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  Websites, blogs and other online platforms
                </p>
              </div>
              <img
                src={Work3}
                alt="image"
                className={` w-full min-h-[210px] lg:h-[300px] object-cover rounded-[0px]`}
              />
            </div>
          </motion.div>

          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className=" flex-[0.4] lg:max-w-[770px] flex-justify-end flex-col gradient-05 sm:p-8 p-4 rounded-[0px]  relative"
          >
            <div className="feedback-gradient absolute top-10 left-20" />

            <div className="flex flex-col">
              {/* <h4
                className={`mt-[50px] font-semibold text-black lg:text-[65px] text-[26px] sm:leading-[40px] leading-[36px]`}
              >
                Where My Work Goes
              </h4> */}

              <div className=" mt-0">
                <img
                  src={Work2}
                  alt="image"
                  className={`w-80 min-h-[210px] lg:h-[400px] object-cover rounded-[0px] `}
                />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </section>

      <section className="sm:p-16 xs:p-8 px-6 py-12  bg-[#FFFAF4] relative z-10">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className={`2xl:max-w-[1280px] w-full mx-auto flex sm:flex-row flex-col `}
        >
          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="flex-[0.4] flex justify-center items-center relative"
          >
            <div className=" flex flex-col ">
              <img
                src={Work4}
                alt="image"
                className={`w-80 min-h-[210px] lg:h-[400px] object-cover rounded-[0px]`}
              />
              <div className=" mt-5">
                <p
                  className={`mt-[8px] mb-5 font-normal sm:text-[22px] text-[18px] sm:leading-[22.68px] leading-[16.68px] text-black underline`}
                >
                  Digital Publications
                </p>
                <p
                  className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  Websites, blogs and other online platforms
                </p>
              </div>
            </div>
          </motion.div>

          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="flex-[1]  lg:max-w-[770px] justify-center items-center flex-col gradient-05 sm:p-8 p-4 rounded-[0px]  relative"
          >
            <div className="feedback-gradient absolute top-10 left-20" />

            <div className="">
              <div className="flex flex-row justify-center items-center w-full  sm:mt-52 mt-6">
                <img
                  src={Work5}
                  alt="image"
                  className={`w-[70%] min-h-[210px] lg:h-[300px] object-cover rounded-[0px] `}
                />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </section>

      <section className="sm:p-16 xs:p-8 px-6 py-12  bg-[#FFFAF4] relative z-10">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className={`2xl:max-w-[1280px] w-full mx-auto flex sm:flex-row-reverse flex-col-reverse `}
        >
          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="flex-[0.2] flex justify-center items-center relative"
          >
            <div className=" flex flex-col sm:mt-52 mt-6">
              <div className=" mb-5">
                <p
                  className={`mt-[8px] mb-5 font-normal sm:text-[22px] text-[18px] sm:leading-[22.68px] leading-[16.68px] text-black underline`}
                >
                  In Studio
                </p>
                <p
                  className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  A fully equipped studio in the city
                </p>
              </div>
              <div className=" mb-5">
                <p
                  className={`mt-[8px] mb-5 font-normal sm:text-[22px] text-[18px] sm:leading-[22.68px] leading-[16.68px] text-black underline`}
                >
                  On-site
                </p>
                <p
                  className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  Venues and environments all over the world
                </p>
              </div>
              <div className=" mb-5">
                <p
                  className={`mt-[8px] mb-5 font-normal sm:text-[22px] text-[18px] sm:leading-[22.68px] leading-[16.68px] text-black underline`}
                >
                  At home
                </p>
                <p
                  className={`mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  Photo-editing and polishing in my home studio
                </p>
              </div>
            </div>
          </motion.div>

          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="flex-1 lg:max-w-[970px] justify-start flex-col gradient-05 sm:p-8 p-4 rounded-[0px]  relative"
          >
            <div className="feedback-gradient absolute top-10 left-20" />

            <div className=" flex flex-row gap-4">
              <div className="flex flex-col">
                <h4
                  className={`mt-[50px] font-semibold text-black lg:text-[65px] text-[26px] sm:leading-[40px] leading-[36px]`}
                >
                  How I Work.
                </h4>

                <div className=" mt-16">
                  <img
                    src={Where}
                    alt="image"
                    className={`w-full min-h-[210px] lg:h-[300px] object-cover rounded-[0px] `}
                  />
                </div>
              </div>

              {/* More Pictures */}
              <div className=" hidden sm:flex">
                <div className=" absolute top-0 left-[36rem]">
                  <img
                    src={Work6}
                    alt="image"
                    className={`w-32`}
                  />
                </div>
                <div className=" absolute bottom-0 right-[3rem]">
                  <img
                    src={Work7}
                    alt="image"
                    className={`w-48`}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </section>

      <section className="sm:p-16 xs:p-8 px-6 py-12  bg-[#ECE6D0] relative z-10">
        <div className=" ">
          <h4
            className={`mt-[50px] font-semibold text-black lg:text-[65px] text-[26px] sm:leading-[40px] leading-[36px]`}
          >
            Capture the moment.
          </h4>
        </div>
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className={`2xl:max-w-[1280px] w-full mx-auto flex sm:flex-row flex-col`}
        >
          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="flex-[0.3] flex justify-center items-center relative"
          >
            <div></div>
          </motion.div>

          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="flex-[1.1]  lg:max-w-[870px] flex-justify-end flex-col gradient-05 sm:p-8 p-4 rounded-[0px]  relative"
          >
            <div className="feedback-gradient absolute top-10 left-20" />
            <div className=" flex sm:flex-row flex-col sm:gap-32 gap-2">
              <div className=" flex flex-col mt-16">
                <p
                  className={` underline uppercase mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black mb-5`}
                >
                  Studio
                </p>
                <p
                  className={`mt-[0px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  123 Anywhere, St., Any City, ST. 12345
                </p>
              </div>
              <div className=" flex flex-col mt-16">
                <p
                  className={` underline uppercase mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black mb-5`}
                >
                  Social
                </p>
                <p
                  className={`mt-[0px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  @blssmstudio.
                </p>
              </div>
              <div className=" flex flex-col mt-16">
                <p
                  className={` underline uppercase mt-[8px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black mb-5`}
                >
                  Email
                </p>
                <p
                  className={`mt-[0px] font-normal sm:text-[18px] text-[12px] sm:leading-[22.68px] leading-[16.68px] text-black`}
                >
                  enquires@blssmstudio.com
                </p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </section>
    </div>
  );
};

export default LandingPage;
