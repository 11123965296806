import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { IoMdAdd, IoMdClose, IoMdRemove } from "react-icons/io";

import { CartContext } from '../contexts/CartContext'

const CartItem = ({ product }) => {
  const { removeFromCart, increaseAmount, decreaseAmount } = useContext(CartContext);

  const { id, image, category, title, price, amount } = product;
  return (
    <div key={id} className=" flex gap-x-4 py-2 lg:px-6 border-b border-gray-200 w-full font-light text-gray-500">
      <div className=" w-full min-h-[150px] flex items-center gap-x-4">
        {/* Image */}
        <Link to={`/product/${id}`}>
          <img src={image} alt={title} className=" max-w-[50px]" />
        </Link>

        <div className=" w-full flex flex-col">
          <div className=" flex justify-between mb-2">
            <Link
              to={`/product/${id}`}
              className=" text-sm capitalize font-medium max-w-[240px] text-primary hover:underline"
            >
              {title}
            </Link>
          <div onClick={()=> removeFromCart(id)}>
            <IoMdClose className=" text-xl text-gray-600 hover:text-red-500 transition duration-300 cursor-pointer" />
          </div>
          </div>

          {/*  */}
          <div className=" bg-[#ECE6D0] flex gap-x-2 h-[36px] items-center">
            <div className=" flex flex-1 max-w-[100px] items-center h-full border text-white font-medium bg-[#000000]">
              <div onClick={()=>decreaseAmount(id)}  className=" flex flex-1 items-center justify-center h-full cursor-pointer">
                <IoMdRemove />
              </div>
              <div className=" h-full flex justify-center items-center px-2">{amount}</div>
              <div onClick={()=>increaseAmount(id)} className="flex flex-1 items-center justify-center h-full cursor-pointer"><IoMdAdd /></div>
            </div> 
            <div className=" flex-1 flex items-center justify-around text-gray-500"> ${price}</div>
            <div className=" flex-1 flex justify-end items-center text-primary font-semibold mr-2">${`${parseFloat(price * amount).toFixed(2)}`}</div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CartItem;
