import React from 'react'
import { BsCheckCircleFill } from 'react-icons/bs'
import { IoMdDownload } from 'react-icons/io'
import { Link } from 'react-router-dom'

const Cancel = () => {
  return (
    <section  className=" bg-[#FFFAF4] h-[800px] bg-hero1 bg-no-repeat bg-cover bg-center py-24">
      <div  className=" container mx-auto flex  justify-around h-full w-80">
        <div  className="  flex flex-1 sm:w-96 w-80 bg-white p-4 rounded-xl border border-gray-500 border-dashed ">
          <div className=" flex flex-col  justify-between items-center w-full " id="pdf-content">
            <div className=" flex flex-col gap-3 w-full items-center mt-6">
              <div className="flex flex-row text-sm text-gray-600 uppercase gap-2 items-center"> <BsCheckCircleFill/> BLSSM Studios.</div>
              <h2 className=" font-semibold text-[#D42900] text-[1.1rem]">
                Payment Failed! ☹️
              </h2>
              <h2 className=" text-3xl font-semibold">Try Again! </h2>
            </div>

            <div className=" flex flex-col gap-5  w-80 sm:p-2 p-4 shadow rounded-md ">
              {/* <div className=" flex flex-row bg-[#F5F6F7] gap-2 text-primary justify-center items-center text-sm p-2 capitalize mt-2 rounded-md">
                <div className=" p-3 text-[1rem] font-medium">
                  Payment Details
                </div>
              </div> */}
              <div className=" items-center justify-center">
                <p className=" text-sm text-gray-500 text-center">Something went wrong, try again</p>
              
              </div>
              {/* <div className=" flex flex-row items-center justify-between justify-items-center">
                <p className=" text-sm text-gray-500">Payment Number</p>
                <p className=" text-sm text-primary font-normal">
                  62874281424743
                </p>
              </div>
              <div className=" flex flex-row items-center justify-between justify-items-center">
                <p className=" text-sm text-gray-500">Payment Time</p>
                <p className=" text-sm text-primary font-normal">
                  21-04-2024, 16:59:00
                </p>
              </div>
              <div className=" flex flex-row items-center justify-between justify-items-center">
                <p className=" text-sm text-gray-500">Sender Name</p>
                <p className=" text-sm text-primary font-normal">John Doe</p>
              </div> */}
            </div>

            <div className=" flex flex-col w-full">
             
              <Link to={`/products`} className=" flex flex-row bg-[#000000] gap-2 text-[#ECE6D0] justify-center items-center text-sm p-2 capitalize mt-2 rounded-md cursor-pointer">
                <div className=" p-2 text-sm font-medium">
                  Back to Products Page
                </div>
              </Link>
            </div>

            {/* <div>Thank you! 🎉</div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cancel
