import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SidebarContext } from "../contexts/SidebarContext";

import { IoMdArrowForward } from "react-icons/io";
import { BsCart4, BsFillTrash2Fill } from "react-icons/bs";
import { BsBag } from 'react-icons/bs';
import { CartContext } from "../contexts/CartContext";
import CartItem from "./CartItem";

const Sidebar = () => {
  const { isOpen, handleClose } = useContext(SidebarContext);
  const { cart, clearCart } = useContext(CartContext);
  const {itemAmount, setItemAmount, total, setTotal} = useContext(CartContext);
  // console.log(useContext(CartContext))

  return (
    <div
      className={` ${
        isOpen ? "right-0" : "-right-full"
      } w-full bg-white fixed top-0 h-full shadow-2xl md:w-[35vw] xl:max-w-[30vw] transition-all duration-300 z-50 px-4 lg:px-[35px] `}
    >
      <div className=" flex flex-row items-center justify-items-center justify-between py-6 border-b">
        <div className=" capitalize font-semibold">Shopping Bag ({itemAmount})</div>
        <div className=" cursor-pointer w-8 h-8 flex justify-center items-center rounded-full">
          <IoMdArrowForward
            onClick={() => handleClose(!isOpen)}
            className={`  text-4xl  bg-slate-200 p-2`}
          />
        </div>
      </div>

      <div className=" flex flex-col   overflow-y-auto overflow-x-hidden scrollbar-hide  h-[550px]">
      {cart.map((product) => {
        return (
          <div className=" ">
            <CartItem product={product} key={product.id} />
          </div>
        );
      })}
      </div>

      {/*  */}
      <div>
        <div className=" absolute bottom-2 right-0 justify-end w-full sm:px-8 px-2 bg-white shadow-lg">
          <div className=" mt-8 flex flex-row justify-between text-xl font-semibold shadow-yellow-200">
            <span className=" text-gray-800">Total:</span>
            <div>${total.toLocaleString()}</div>
          </div>
          <div onClick={()=> handleClose(!isOpen)} className=" flex flex-row cursor-pointer bg-gray-300 hover:bg-black hover:text-[#ECE6D0] gap-2 text-primary justify-center items-center text-sm p-2 uppercase transition ease-in duration-300 mt-2">
            <div>Continue Shopping</div>
          </div>
          <Link to={`/checkout`}  className=" flex flex-row cursor-pointer bg-[#8AB415] hover:bg-black hover:text-[#ECE6D0] gap-2 text-white justify-center items-center text-sm p-2 uppercase transition ease-in duration-300 mt-2">
            <BsCart4 /> 
            <div>Checkout</div>
          </Link>
          {/* <div onClick={clearCart} className=" flex flex-row cursor-pointer bg-[#8AB415] hover:bg-black hover:text-[#ECE6D0] gap-2 text-white justify-center items-center text-sm p-2 uppercase transition ease-in duration-300 mt-2">
            <BsCart4 /> 
            <div>Checkout</div>
          </div> */}
          
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
